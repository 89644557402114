import { ResetPasswordSchema } from "@validators/auth";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import toast from "react-hot-toast";
import { resendConfirmationCode } from "./resendConfirmationCode";

export const resetPasswordConfirmation = async (userPool: CognitoUserPool, { code, email, password }: ResetPasswordSchema) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  user.confirmPassword(code.trim(), password, {
    onSuccess: () => {
      toast.success("Your password has changed. Login with your new password");
    },
    onFailure: (err) => {
      let userMessage = `An error occured confirming your email. Please try again or contact us if this error persists. \n Error: ${err.message}`;

      switch (err.name) {
        case "CodeMismatchException": {
          userMessage = "Incorrect Verification Code. Please try again.";
          break;
        }
        case "ExpiredCodeException": {
          userMessage = "This code has expired. We have sent a new code. Please check your email and enter the new code.";
          resendConfirmationCode(userPool, email);
          break;
        }
        case "InvalidPasswordException": {
          userMessage = "Your password doesn't meet the policy.  See password policy requirements below.";
          break;
        }
        case "TooManyFailedAttemptsException": {
          userMessage = "Too many failed attempts. Please wait 10 minutes and try again.";
          break;
        }
        case "LimitExceededException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        case "TooManyRequestsException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        default: {
          break;
        }
      }

      toast.error(userMessage);
    },
  });
};
