import { Button } from "@components/Catalyst/button";
import InputField from "@components/FormControls/CatalystTextField";
import { useAuth } from "@contexts/AuthContext";
import { forgotPasswordRequest } from "@contexts/AuthContext/functions/forgotPassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordSchema, forgotPasswordSchema } from "@validators/auth";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Text } from "@components/Catalyst/text";
import { FieldGroup, Fieldset } from "@components/Catalyst/fieldset";

const SmallForgotPasswordForm = ({ onCompletion }: { onCompletion: (email: string) => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ForgotPasswordSchema>({ resolver: yupResolver(forgotPasswordSchema), mode: "onChange" });

  const { getCognitoUserPool } = useAuth();

  const onSubmit: SubmitHandler<ForgotPasswordSchema> = async (data) => {
    const cognitoUserPool = await getCognitoUserPool();

    await forgotPasswordRequest(cognitoUserPool, data);

    onCompletion(data.email);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <FieldGroup>
          <Text>A reset code will be sent to the email address provided. You&apos;ll enter it here along with your new password.</Text>

          <InputField required label="Email" placeholder="Registered email" register={register} name="email" error={errors.email} />

          <Button type="submit" disabled={!isValid || isSubmitting}>
            Send code
          </Button>
        </FieldGroup>
      </Fieldset>
    </form>
  );
};

export default SmallForgotPasswordForm;
