import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React from "react";

export const Fieldset = ({ className, ...props }: { className?: string } & Omit<Headless.FieldsetProps, "as" | "className">) => (
  <Headless.Fieldset {...props} className={clsx(className, "[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1")} />
);

export const Legend = ({ className, ...props }: { className?: string } & Omit<Headless.LegendProps, "as" | "className">) => (
  <Headless.Legend
    data-slot="legend"
    {...props}
    className={clsx(className, "text-base/6 font-semibold text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white")}
  />
);

export const FieldGroup = ({ className, ...props }: React.ComponentPropsWithoutRef<"div">) => (
  <div data-slot="control" {...props} className={clsx(className, "space-y-8")} />
);

export const Field = ({ className, ...props }: { className?: string } & Omit<Headless.FieldProps, "as" | "className">) => (
  <Headless.Field
    {...props}
    className={clsx(
      className,
      "[&>[data-slot=label]+[data-slot=control]]:mt-3",
      "[&>[data-slot=label]+[data-slot=description]]:mt-1",
      "[&>[data-slot=description]+[data-slot=control]]:mt-3",
      "[&>[data-slot=control]+[data-slot=description]]:mt-3",
      "[&>[data-slot=control]+[data-slot=error]]:mt-3",
      "[&>[data-slot=label]]:font-medium",
    )}
  />
);

export const Label = ({ className, ...props }: { className?: string } & Omit<Headless.LabelProps, "as" | "className">) => (
  <Headless.Label
    data-slot="label"
    {...props}
    className={clsx(className, "select-none text-base/6 text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white")}
  />
);

export const Description = ({ className, ...props }: { className?: string } & Omit<Headless.DescriptionProps, "as" | "className">) => (
  <Headless.Description
    data-slot="description"
    {...props}
    className={clsx(className, "text-base/6 text-zinc-500 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-zinc-400")}
  />
);

export const ErrorMessage = ({ className, ...props }: { className?: string } & Omit<Headless.DescriptionProps, "as" | "className">) => (
  <Headless.Description
    data-slot="error"
    {...props}
    className={clsx(className, "text-base/6 text-red-600 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-red-500")}
  />
);
