import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import toast from "react-hot-toast";

export const resendConfirmationCode = (userPool: CognitoUserPool, email: string) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  user.resendConfirmationCode((err, result) => {
    if (err) {
      let userMessage = `An error occurred sending this code. Please try again or contact us if this error persists. \n Error: ${err.message}`;

      switch (err.name) {
        case "CodeDeliveryFailureException": {
          userMessage = "We could not send the code to this email. Please try again in a few minutes or contact us if this error persists.";
          break;
        }
        case "LimitExceededException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        case "TooManyRequestsException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        default: {
          break;
        }
      }

      toast.error(userMessage);
      return;
    }

    if (result) {
      toast.success("Code is sent to your email. Please check your email.");
    }
  });
};
