import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { VerificationSchema } from "@validators/auth";
import toast from "react-hot-toast";
import { navigate } from "gatsby";
import { resendConfirmationCode } from "./resendConfirmationCode";

export const registerConfirmation = async (userPool: CognitoUserPool, { email, code }: VerificationSchema) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  user.confirmRegistration(code, true, (err, result) => {
    if (err) {
      let userMessage = `An error occured confirming your email. Please try again or contact us if this error persists. \n Error: ${err.message}`;

      switch (err.name) {
        case "ExpiredCodeException": {
          userMessage = "This code has expired. We have sent a new code. Please check your email and enter the new code.";
          resendConfirmationCode(userPool, email);
          break;
        }
        case "NotAuthorizedException": {
          if (err.message.includes("Current status is CONFIRMED")) {
            toast.error("This account has already been confirmed. Please login.");
            navigate("/login");
            return;
          }

          userMessage = `An error occured confirming your email. Please try again or contact us if this error persists. \n Error:  ${err.message}`;

          break;
        }
        case "CodeMismatchException": {
          userMessage = "Incorrect code. Please try again.";
          break;
        }
        case "TooManyFailedAttemptsException": {
          userMessage = "Too many failed attempts. Please wait 10 minutes and try again.";
          break;
        }
        case "LimitExceededException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        case "TooManyRequestsException": {
          userMessage = "Too many requests. Please wait a minute and try again.";
          break;
        }
        default: {
          break;
        }
      }

      toast.error(userMessage);
      return;
    }

    if (result) {
      toast.success("Thank you for confirming your registration! You can now login");
    }
  });
};
