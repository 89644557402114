import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

export const getInternalToken = async (cognitoUser: CognitoUser): Promise<{ token: string; expire: number } | null> =>
  new Promise((resolve, reject) => {
    if (!cognitoUser) {
      resolve(null);
      return;
    }

    cognitoUser.getSession((err: Error | null, data: CognitoUserSession) => {
      if (err) {
        if (cognitoUser) cognitoUser.signOut();

        reject(err);
        return;
      }

      const accessToken = data.getIdToken();

      const token = accessToken?.getJwtToken();
      resolve({ token, expire: accessToken.getExpiration() });
    });

    resolve(null);
  });
