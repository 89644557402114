import { Button } from "@components/Catalyst/button";
import { ErrorMessage, Field, Label } from "@components/Catalyst/fieldset";
import { Input } from "@components/Catalyst/input";
import { Text } from "@components/Catalyst/text";
import { Textarea } from "@components/Catalyst/textarea";
import { EyeIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import React, { useState } from "react";
import { FieldError, FieldErrorsImpl, FieldValues, Merge, Path } from "react-hook-form/dist/types";
import { UseFormRegister } from "react-hook-form/dist/types/form";

interface TextFieldProps<T extends FieldValues = FieldValues> {
  label?: string;
  required?: boolean;
  name: Path<T>;
  register: UseFormRegister<T>;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  placeholder?: string;
  textarea?: boolean;
  type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url" | "date" | "datetime-local" | "month" | "time" | "week";
  helperText?: string;
  autocomplete?: string;
  value?: string;
  disabled?: boolean;
  defaultValue?: string;
  isPassword?: boolean;
  className?: string;
}

const CatalystTextField = <T extends FieldValues = FieldValues>({
  helperText,
  type = "text",
  label,
  required = false,
  register,
  name,
  error,
  autocomplete,
  placeholder,
  textarea,
  value,
  disabled,
  defaultValue,
  className,
}: TextFieldProps<T>) => {
  const [password] = useState(type === "password");
  const [showPassword, setShowPasswod] = useState(false);

  let calculatedType = type;
  if (password) calculatedType = showPassword ? "text" : "password";

  return (
    <Field className={clsx({ "max-w-md": !(className && className.indexOf("w-")) }, className)}>
      {label && (
        <Label>
          {label}
          {required && <>&nbsp;*</>}
        </Label>
      )}
      {textarea ? (
        <Textarea rows={6} placeholder={placeholder} {...register(name)} value={value} disabled={disabled} defaultValue={defaultValue} />
      ) : (
        <div className="flex">
          <Input
            type={calculatedType}
            {...register(name)}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            defaultValue={defaultValue}
            autoComplete={autocomplete}
          />
          {type === "password" && (
            <Button plain onClick={() => setShowPasswod((prev) => !prev)}>
              <EyeIcon />
            </Button>
          )}
        </div>
      )}
      {!!helperText && <Text className="text-sm">{helperText}</Text>}
      {!!error && <ErrorMessage>{error.message as string}</ErrorMessage>}
    </Field>
  );
};

CatalystTextField.defaultProps = {
  label: "",
  required: false,
  error: {},
};

export default CatalystTextField;
