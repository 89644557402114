import { CognitoUser } from "amazon-cognito-identity-js";

export const logout = async (user: CognitoUser | null) =>
  new Promise<void>((resolve) => {
    if (!user) {
      resolve();
    }

    user?.signOut(() => {
      resolve();
    });
  });
