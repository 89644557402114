import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { ForgotPasswordSchema } from "@validators/auth";
import toast from "react-hot-toast";

export const forgotPasswordRequest = async (userPool: CognitoUserPool, { email }: ForgotPasswordSchema) =>
  new Promise<void>((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.forgotPassword({
      onSuccess: () => {
        toast.success("Reset password code has been sent. Please check your email.");
        resolve();
      },
      onFailure: (err) => {
        let userMessage = `An error occured while changing your password. Please try again or contact us if this error persists. \n ${err.message}`;

        switch (err.name) {
          case "UserNotFoundException": {
            userMessage = "User not found. Confirm email address or register for an account.";
            break;
          }
          case "CodeDeliveryFailureException": {
            userMessage = "Please check your email address.  We couldnt send it a verification code.";
            break;
          }
          case "LimitExceededException": {
            userMessage = "Too many requests. Please wait a minute and try again.";
            break;
          }
          case "TooManyRequestsException": {
            userMessage = "Too many requests. Please wait a minute and try again.";
            break;
          }
          default: {
            break;
          }
        }

        toast.error(userMessage);
        reject(userMessage);
      },
    });
  });
