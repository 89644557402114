/* eslint-disable jsx-a11y/anchor-has-content */
/**
 * TODO: Update this component to use your client-side framework's link
 * component. We've provided examples of how to do this for Next.js, Remix, and
 * Inertia.js in the Catalyst documentation:
 *
 * https://catalyst.tailwindui.com/docs#client-side-router-integration
 */

import * as Headless from "@headlessui/react";
import React, { forwardRef } from "react";

import { Link as GatsbyLink } from "gatsby-link";

export const Link = forwardRef<HTMLAnchorElement, { href: string } & React.ComponentPropsWithoutRef<"a">>((props, ref) => (
  <Headless.DataInteractive>
    {props.href.startsWith("http") || props.href.startsWith("mailto") || props.href.startsWith("tel") ? (
      <a {...props} ref={ref as React.Ref<any>} />
    ) : (
      <GatsbyLink to={props.href} ref={ref as React.Ref<any>} {...props} />
    )}
  </Headless.DataInteractive>
));
