import InputField from "@components/FormControls/CatalystTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { VerificationSchema, verificationSchema } from "@validators/auth";
import React from "react";
import { useForm } from "react-hook-form";
import { resendConfirmationCode } from "@contexts/AuthContext/functions/resendConfirmationCode";
import { registerConfirmation } from "@contexts/AuthContext/functions/registerConfirmation";
import { useAuth } from "@contexts/AuthContext";
import { Fieldset } from "@headlessui/react";
import { FieldGroup } from "@components/Catalyst/fieldset";
import { Text } from "@components/Catalyst/text";
import { Button } from "@components/Catalyst/button";
import toast from "react-hot-toast";

const SmallConfirmRegistrationForm = ({ email, onCompletion }: { email: string; onCompletion: () => void }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm<VerificationSchema>({
    resolver: yupResolver(verificationSchema),
    defaultValues: { email },
    mode: "onChange",
  });

  const { getCognitoUserPool } = useAuth();

  const onSubmit = async (data: any) => {
    getCognitoUserPool().then((cognitoUserPool) => {
      registerConfirmation(cognitoUserPool, data).then(() => {
        if (onCompletion) onCompletion();
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <FieldGroup>
          <Text>We sent a verification code to your email. Please enter it below. </Text>

          <InputField label="Email" register={register} name="email" error={errors.email} />

          <InputField label="Verification Code" placeholder="Code from your email" register={register} name="code" error={errors.code} />

          <div className="flex place-content-between">
            <Button type="submit" disabled={isSubmitting || !isValid}>
              Submit
            </Button>

            <Button
              type="button"
              outline
              onClick={async () => {
                const formEmail = getValues("email");

                if (!formEmail) {
                  toast.error("Email is required to resend the confirmation code.");
                  return;
                }

                const cognitoUserPool = await getCognitoUserPool();

                console.log("Resending code to: ", formEmail);
                resendConfirmationCode(cognitoUserPool, formEmail);
              }}
            >
              Resend Code
            </Button>
          </div>
        </FieldGroup>
      </Fieldset>
    </form>
  );
};

export default SmallConfirmRegistrationForm;
