import InputField from "@components/FormControls/CatalystTextField";

import { useAuth } from "@contexts/AuthContext";
import { Button } from "@components/Catalyst/button";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema, loginSchema } from "@validators/auth";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Fieldset, FieldGroup } from "@components/Catalyst/fieldset";

const LoginForm = ({ email, closeMenu }: { email: string; closeMenu?: () => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<LoginSchema>({ resolver: yupResolver(loginSchema), defaultValues: { email }, mode: "onChange" });

  const { login } = useAuth();

  const processLogin = async (loginemail: string, password: string): Promise<void> => {
    toast.promise(
      login(loginemail, password).then((_user) => {
        closeMenu?.();
      }),
      {
        loading: "Logging in...",
        success: "Successfully logged in!",
        error: (err) => {
          let userMessage = "An error occured while while logging in. Please try again or contact us if this error persists.";

          switch (err.name) {
            case "UserNotFoundException": {
              userMessage = "User not found. Confirm email address or register for an account.";
              break;
            }
            case "NotAuthorizedException": {
              userMessage = "Incorrect email or password. Please try again.";
              break;
            }
            case "UserNotConfirmedException": {
              userMessage = "Your email is not confirmed.  Check your email for the verification code.";
              window.open(`/register-confirmation?email=${email}`, "_blank");

              break;
            }

            case "LimitExceededException": {
              userMessage = "Too many requests. Please wait a minute and try again.";
              break;
            }
            case "TooManyRequestsException": {
              userMessage = "Too many requests. Please wait a minute and try again.";
              break;
            }
            default: {
              break;
            }
          }

          return userMessage;
        },
      },
    );
  };

  const onSubmit = async (data: LoginSchema) => {
    processLogin(data.email, data.password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <FieldGroup>
          <InputField
            className="max-w-sm"
            label="Email"
            autocomplete="username"
            register={register}
            name="email"
            type="email"
            error={errors.email}
            required
          />
          <InputField
            className="max-w-sm"
            label="Password"
            register={register}
            name="password"
            type="password"
            autocomplete="current-password"
            error={errors.password}
            required
            isPassword
          />

          <Button type="submit" disabled={!isValid || isSubmitting}>
            Login
          </Button>
        </FieldGroup>
      </Fieldset>
    </form>
  );
};

export default LoginForm;
