import { RegistrationData } from "../types";

export const setRegistrationData = (data: RegistrationData | null) => {
  if (window && !data) {
    sessionStorage.removeItem("GACRegistrationData");
    return;
  }

  if (window) sessionStorage.setItem("GACRegistrationData", JSON.stringify(data));
};
