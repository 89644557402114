import { LoginSchema } from "@validators/auth";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

export const login = async (
  getCognitoUserPool: () => Promise<CognitoUserPool>,
  { email, password }: LoginSchema,
): Promise<CognitoUser | null> => {
  const gacUserPool = await getCognitoUserPool();

  const user = new CognitoUser({
    Username: email,
    Pool: gacUserPool,
  });

  const auth = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise((resolve, reject) => {
    user.authenticateUser(auth, {
      onSuccess: async (_data) => {
        // toast.success("You have successfully logged in. ");
        resolve(user);
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: () => reject(new Error("New password required.")),
    });
  });
};
