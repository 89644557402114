import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

export const getEmailFromCurrentUser = async (cognitoUser: CognitoUser | null): Promise<string | null> =>
  new Promise((resolve, reject) => {
    if (!cognitoUser) resolve(null);

    if (cognitoUser) {
      cognitoUser.getSession((err: Error | null, data: CognitoUserSession) => {
        if (err) {
          reject(new Error("Couldnt get session"));
        }

        const accessToken = data.getIdToken();

        const tokenEmail = accessToken.decodePayload().email;

        resolve(tokenEmail);
      });
    }
    resolve(null);
  });
