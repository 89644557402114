import InputField from "@components/FormControls/CatalystTextField";
import { Button } from "@components/Catalyst/button";
import { useAuth } from "@contexts/AuthContext";
import { resetPasswordConfirmation } from "@contexts/AuthContext/functions/resetPassword";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text } from "@components/Catalyst/text";
import { ResetPasswordSchema, resetPasswordSchema } from "@validators/auth";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FieldGroup } from "@components/Catalyst/fieldset";
import { Fieldset } from "@headlessui/react";

const SmallResetPasswordForm = ({ email, onCompletion }: { email: string; onCompletion: () => void }) => {
  const { getCognitoUserPool } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ResetPasswordSchema>({ resolver: yupResolver(resetPasswordSchema), defaultValues: { email }, mode: "onChange" });

  const onSubmit: SubmitHandler<ResetPasswordSchema> = async (data) => {
    const cognitoUserPool = await getCognitoUserPool();
    await resetPasswordConfirmation(cognitoUserPool, data);

    onCompletion();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <FieldGroup>
          <InputField label="Email" register={register} name="email" error={errors.email} />

          <InputField className="w-1/2" label="Verification Code" register={register} name="code" required error={errors.code} />

          <div className="flex flex-col gap-1">
            <InputField
              className="w-1/2"
              label="New Password"
              type="password"
              register={register}
              name="password"
              required
              error={errors.password}
              autocomplete="new-password"
            />

            <InputField
              className="w-1/2"
              type="password"
              register={register}
              name="passwordConfirmation"
              required
              placeholder="Confirm Password"
              error={errors.passwordConfirmation}
              autocomplete="new-password"
            />
            <Text>
              Password must be at least 8 characters in length and include a combination of upper and lowercase letters, numbers, and
              symbols
            </Text>
          </div>

          <div className="flex content-center gap-2">
            <Button type="submit" disabled={!isValid || isSubmitting}>
              Submit
            </Button>

            {!isValid && <Text className="content-center items-center">Please fix errors to submit</Text>}
          </div>
        </FieldGroup>
      </Fieldset>
    </form>
  );
};

export default SmallResetPasswordForm;
